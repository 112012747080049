
export const environment = {
  production: false,
  envVar: {
    PORTAL: "false",
    PORTAL_URL: "https://localhost:5000/portal/home",
    NODE_URL: "https://systnaps-devutil.huxium.net",
      API_URL: "https://c1d4e8eb.dm.systnaps.com/ddtm-api/web",
      ANGULAR_URL: "http://localhost:4200",
      LOGO: "logo.png",
      BlACKLOGO: "Black-Logo.png",
      DRI_URL: "https://dataset.huxium.io"
}
};
